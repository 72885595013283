import { Container, Flex, Heading, List, ListItem, Text } from '@chakra-ui/react';
import { graphql } from 'gatsby';
import React, { FC } from 'react';
import { GalleryCard } from '../components/GalleryCard';
import { Layout } from '../components/Layout';
import { QuestionCard } from '../components/QuestionCard';
import '../global.css';
import { GalleriesQueryResult } from '../types';

interface Props {
    data: GalleriesQueryResult;
}

const Page: FC<Props> = ({ data }) => {
    return (
        <Layout title="Poręcze schodowe wewnętrzne i zewnętrzne">
            <Container alignSelf="center" maxW={['container.sm', 'container.xl']} px={['4', '10']} mt={['4', '12']}>
                <Flex gap="10" direction="column">
                    <Heading
                        id="realizacje"
                        scrollMarginTop="4"
                        mx={['12', '0']}
                        as="h2"
                        size="2xl"
                        variant="secondary"
                        alignSelf="flex-start"
                    >
                        Poręcze schodowe wewnętrzne i&nbsp;zewnętrzne
                    </Heading>

                    <GalleryCard fileEdges={data.balu.edges} id="realizacje-balustrady" title="Poręcze schodowe" />

                    <QuestionCard defaultExpanded title="Nasze poręcze">
                        <Heading as="h4" size="md">
                            Bezpieczeństwo
                        </Heading>
                        <Text mt={['2']}>
                            Poręcze schodowe to nie tylko estetyczny dodatek do Twojego domu, ale także ważny element
                            zapewniający bezpieczeństwo Twojej rodziny i gości.
                        </Text>

                        <Heading as="h4" size="md" mt={['6']}>
                            Wysoka jakość
                        </Heading>
                        <Text mt={['2']}>
                            Nasze produkty są wykonane z najwyższej jakości materiałów, co gwarantuje ich trwałość i
                            długą żywotność.
                        </Text>

                        <Heading as="h4" size="md" mt={['6']}>
                            Różnorodność
                        </Heading>
                        <Text mt={['2']}>
                            Szeroki wybór produktów zapewnia, że znajdziesz coś dopasowanego do Twojego stylu i gustu.
                        </Text>

                        <Heading as="h4" size="md" mt={['6']}>
                            Montaż
                        </Heading>
                        <Text mt={['2']}>
                            Nasza firma oferuje profesjonalny montaż, dzięki czemu masz pewność, że są one zamontowane
                            zgodnie z normami bezpieczeństwa.
                        </Text>
                    </QuestionCard>
                </Flex>
            </Container>
        </Layout>
    );
};

export default Page;

/*
TODO:
* improve SEO (roobts.txt)
* play with the images sizes & formats (only jpg for full?)
* improve desktop gallery

*/
export const query = graphql`
    fragment MyGallery on FileConnection {
        edges {
            node {
                id
                name
                thumb: childImageSharp {
                    gatsbyImageData(height: 300, placeholder: BLURRED, layout: CONSTRAINED)
                }
                full: childImageSharp {
                    gatsbyImageData(placeholder: BLURRED, layout: CONSTRAINED)
                }
            }
        }
    }

    query BalQuery {
        balu: allFile(filter: { relativeDirectory: { eq: "galleries/balu" } }) {
            ...MyGallery
        }
    }
`;
